import { ConnectButton } from '@rainbow-me/rainbowkit'
import './wallet-button.scss'

const WalletButton = () => (
  <div className="flex justify-end mt-4">
    <ConnectButton.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
        return (
          <div
            {...(!mounted && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
            className="bg-no-repeat bg-transparent bg-contain bg-center hover:opacity-95 transition-opacity duration-200 ease-in-out "
          >
            {(() => {
              if (!mounted || !account || !chain) {
                return (
                  <button
                    onClick={openConnectModal}
                    type="button"
                    className="connect-wallet-btn uppercase bg-[#53ffa6] text-[#000b05] text-2xl py-2"
                  >
                    <img src="/mechachaotic-logo-black.png" className="w-16 inline border-black border-solid border-2 mx-2" alt="Logo" />
                    <span className="p-2">Connect Wallet</span>
                  </button>
                )
              }
              if (chain.unsupported) {
                return (
                  <button
                    onClick={openChainModal}
                    type="button"
                    className="uppercase h-20 uppercase bg-[#53ffa6] text-[#000b05] text-2xl px-6 py-2 text-xl lg:text-4xl"
                  >
                    Wrong network
                  </button>
                )
              }
              return (
                <div style={{ display: 'inline' }}>
                  <button
                    onClick={openAccountModal}
                    type="button"
                    className="uppercase bg-[#53ffa6] text-[#000b05] text-2xl px-24 py-2"
                    style={{ minHeight: '5rem' }}
                  >
                    {chain.hasIcon && (
                      <a
                        style={{
                          background: chain.iconBackground,
                          borderRadius: 999,
                          overflow: 'hidden',
                          marginRight: 4,
                          display: 'inherit',
                        }}
                        className="w-4 h-4 md:h-6 md:w-6"
                        onClick={openChainModal}
                        href="#"
                      >
                        {chain.iconUrl && <img alt={chain.name ?? 'Chain icon'} src={chain.iconUrl} className="w-4 h-4 md:w-6 md:h-6" />}
                      </a>
                    )}
                    <div className="inline text-xl lg:text-2xl">
                      {account.displayName}
                      {account.displayBalance ? ` (${account.displayBalance})` : ''}
                    </div>
                  </button>
                </div>
              )
            })()}
          </div>
        )
      }}
    </ConnectButton.Custom>
  </div>
)

export default WalletButton
