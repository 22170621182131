/*function importAll(r) {
  return r.keys().map(r)
}

const images = importAll(require.context('../public/art', false, /\.(png)$/))

export const listofImages = images.map((item) => {
  return item
})
Need to move this to .js file if we want to use it again. */ 


export const IsJsonString = (str: string) => {
  try {
    var json = JSON.parse(str)
    return typeof json === 'object'
  } catch (e) {
    return false
  }
}

export const hexEncode = (str: string): `0x${string}` => {
  var hex, i;

  var result = "";
  for (i=0; i<str.length; i++) {
      hex = str.charCodeAt(i).toString(16);
      result += ("000"+hex).slice(-4);
  }

  return result as `0x${string}`
}