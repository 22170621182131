import { serializeError } from 'eth-rpc-errors'
import { SerializedEthereumRpcError } from 'eth-rpc-errors/dist/classes'

type MetaMaskError = SerializedEthereumRpcError & {
  data: {
    originalError: {
      reason: string
    }
  }
}

export const parseError = (err: Error): string => {
  try {
    const fallbackError = { code: 4999, message: 'Some unknown error occured' }
    const error = serializeError(err, { fallbackError })
    if (!!error) {
      return (error as MetaMaskError)?.data?.originalError?.reason ?? error.message
    }

    throw null
  } catch (e) {
    return err?.message ?? 'Some unknown error occured'
  }
}
