import { enqueueSnackbar } from 'notistack'
import { parse } from 'papaparse'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useAccount, useContractRead, useSigner } from 'wagmi'
import { CONTRACT_ADDRESS } from '../../constants/mint'
import CONTRACT_ABI from '../../MechaChaoticABI'
import { WalletData, WhitelistData } from '../../types/mint'
import ClaimReserveMint from './mints/claim-reserve'
import PrivateMint from './mints/private'
import PublicMint from './mints/public'
import ReserveMint from './mints/reserve'
import Web3, { ethers } from 'ethers'

const contractConfig = {
  addressOrName: CONTRACT_ADDRESS,
  address: CONTRACT_ADDRESS,
  abi: CONTRACT_ABI,
}

const API_URL = process.env.REACT_APP_API_URL

const RainbowKitMint = memo(() => {
  const { address } = useAccount()
  const { data: signer } = useSigner()

  const [contract, setContract] = useState<Web3.ethers.Contract>()
  const [walletData, setWalletData] = useState<WalletData>()
  const [publicTotalMinted, setPublicTotalMinted] = useState<number>(0)
  const [privateTotalMinted, setPrivateTotalMinted] = useState<number>(0)
  const [reserveTotalMinted, setReserveTotalMinted] = useState<number>(0)
  const [whitelistData, setWhitelistData] = useState<WhitelistData[]>()

  /* Set totalSupply of NFTs minted so far for this contract */
  const {
    data: totalMintsFromWallet,
    status: totalMintsFromWalletStatus,
    refetch: refetchTotalMints,
  } = useContractRead({
    ...contractConfig,
    functionName: 'holders',
    watch: true,
    enabled: !!walletData?.walletAddress,
    args: [walletData?.walletAddress],
    onSuccess(data) {
      if (!!data) {
        calculateRemainingMintable()
      }
    },
    onError(err) {
      if (!!address) {
        enqueueSnackbar('Error refetching total mints: ' + err.message, { variant: 'error' })
      }
    },
  })

  const { data: isPublicMintOpen } = useContractRead({
    ...contractConfig,
    functionName: 'isPublicMintOpen',
  })

  const { data: isPrivateMintOpen } = useContractRead({
    ...contractConfig,
    functionName: 'isPrivateMintOpen',
  })

  const { data: isClaimMintOpen } = useContractRead({
    ...contractConfig,
    functionName: 'isClaimMintOpen',
  })

  const calculateRemainingMintable = useCallback(async () => {
    const mintTotalVals = totalMintsFromWallet as any

    setPublicTotalMinted(mintTotalVals?.mintedTokens)
    setPrivateTotalMinted(mintTotalVals?.privateMintedTokens)
    setReserveTotalMinted(mintTotalVals?.reservedSpots)
  }, [totalMintsFromWallet])

  /* Parse Whitelist CSV File on initial render. */
  useEffect(() => {
    parse<WhitelistData>(`${API_URL}/csv`, {
      delimiter: ',',
      download: true,
      header: true,
      complete: (results) => {
        setWhitelistData(results.data)
      },
    })
  }, [])

  useEffect(() => {
    setWalletData({
      connected: true,
      walletAddress: `${address}`,
      chainId: 5 /* IMPORTANT: Make sure chainId value is properly set to match what is in src/index.tsx */,
    })
  }, [address])

  useEffect(() => {
    if (!!address && !!signer) {
      const contract = new ethers.Contract(CONTRACT_ADDRESS as string, CONTRACT_ABI, signer as Web3.ethers.Signer)
      setContract(contract)
    } else {
      setContract(undefined)
    }
  }, [address, signer])

  const walletOnWhitelist = useMemo(() => {
    return whitelistData?.find((data) => data.walletAddress?.toLowerCase() === address?.toLowerCase())
  }, [walletData, whitelistData])

  return (
    <div className="page rainbowkit-page">
      <>
        {isPublicMintOpen && (
          <PublicMint contract={contract} totalMinted={publicTotalMinted} walletData={walletData} refetchMints={refetchTotalMints} />
        )}

        {isPrivateMintOpen && (walletOnWhitelist?.maxMints || 0) > 0 && (
          <PrivateMint
            contract={contract}
            totalMinted={privateTotalMinted}
            walletData={walletData}
            maxMints={walletOnWhitelist?.maxMints ?? '0'}
            refetchMints={refetchTotalMints}
            totalMintsFromWalletStatus={totalMintsFromWalletStatus}
          />
        )}

        <ReserveMint contract={contract} totalMinted={reserveTotalMinted} walletData={walletData} refetchMints={refetchTotalMints} />

        {isClaimMintOpen && <ClaimReserveMint contract={contract} />}
      </>
    </div>
  )
})

export default RainbowKitMint
