import { BaseMintProps, MINT_TYPES } from '../../../types/mint'
import MintTemplate from './template'
import useMint from '../../../hooks/useMint'

const TITLE = 'Reserve Mint'
const { RESERVE } = MINT_TYPES

const ReserveMint = ({ contract, totalMinted: reserveTotalMinted, walletData, refetchMints }: BaseMintProps) => {
  const {
    numberToMint,
    setNumberToMint,
    mint: reserveMint,
    mintStatus,
    mintStatusType,
    isMintLoading,
  } = useMint(TITLE, contract, walletData?.walletAddress ?? '', RESERVE, refetchMints)

  return (
    <MintTemplate
      title={TITLE}
      image="/6193a9d734b645095290f06a_2k_Sneak_Peek.png"
      type={RESERVE}
      numberToMint={numberToMint}
      setNumberToMint={setNumberToMint}
      totalMinted={reserveTotalMinted}
      mintStatus={mintStatus}
      mintStatusType={mintStatusType}
      isMintLoading={isMintLoading}
      mint={reserveMint}
    />
  )
}

export default ReserveMint
