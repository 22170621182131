import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { useAccount } from 'wagmi'
import { getECDSASignature } from '../queries/mint'
import { IMintTypes, MintStatusTypes, MINT_STATUS, MINT_TYPES } from '../types/mint'
import { ethers } from 'ethers'
import { parseError } from '../utils/errors'
import Web3 from 'ethers'
import { MINT_PRICE_ETH } from '../constants/mint'

const { PENDING_ECDSA, PENDING_CONTRACT_WRITE, PENDING_CONTRACT_RESPONSE, ERROR, SUCCESS } = MINT_STATUS
const { PUBLIC, PRIVATE, RESERVE, CLAIM_RESERVED } = MINT_TYPES

const useMint = (
  title: string,
  contract: Web3.ethers.Contract | undefined,
  walletAddress: string,
  functionName: IMintTypes,
  refetchMints?: () => void,
  maxMints: string = ''
) => {
  const { address } = useAccount()

  const [numberToMint, setNumberToMint] = useState<number>(0)
  const [mintStatus, setMintStatus] = useState<string>('')
  const [mintStatusType, setMintStatusType] = useState<MintStatusTypes | undefined>()
  // const [enableMintConfig, setEnableMintConfig] = useState<boolean>(false)
  // const [ECDSASignature, setECDSASignature] = useState<string>(BASE_SIGNATURE)
  const [isMintLoading, setIsMintLoading] = useState<boolean>(false)

  const mint = async () => {
    try {
      if (!address) {
        enqueueSnackbar(`${title} Error: You must connect to a wallet first.`, { variant: 'error' })
        return
      }

      if (!contract) {
        enqueueSnackbar(`${title} Error: Contract not set.`, { variant: 'error' })
        return
      }

      let signature = ''
      if (!!walletAddress) {
        setMintStatusType(PENDING_ECDSA)
        setMintStatus(`Loading ECDSA Signature`)

        signature = await getECDSASignature(walletAddress, numberToMint, maxMints)
      }

      setMintStatusType(PENDING_CONTRACT_WRITE)
      setMintStatus(`Writing to Smart Contract Mint Function`)

      callMintOnContract_(signature)
    } catch (err: unknown) {
      setMintStatusType(ERROR)

      let errMsg = 'Some error occured while trying to get ECDSA'
      if (err instanceof Error && err?.message) {
        errMsg = err?.message
      }
      setMintStatus(errMsg)
      enqueueSnackbar(`${title} Error: ${errMsg}`, { variant: 'error' })
    }
  }

  const callMintOnContract_ = async (signature: string) => {
    try {
      setIsMintLoading(true)
      setMintStatusType(PENDING_CONTRACT_RESPONSE)
      setMintStatus(`Waiting for Smart Contract Response`)
      let response

      switch (functionName) {
        case PRIVATE:
          response = await contract?.[functionName]?.(ethers.BigNumber.from(numberToMint), maxMints, signature, {
            value: ethers.utils.parseEther(MINT_PRICE_ETH).mul(numberToMint),
          })
          break
        case PUBLIC:
        case RESERVE:
          response = await contract?.[functionName]?.(ethers.BigNumber.from(numberToMint), signature, {
            value: ethers.utils.parseEther(MINT_PRICE_ETH).mul(numberToMint),
          })
          break
        case CLAIM_RESERVED:
          response = await contract?.[functionName]?.()
          break
        default:
          throw new Error('Unknown mint')
      }

      await response?.wait()

      setMintStatusType(SUCCESS)
      setMintStatus(functionName === RESERVE ? 'Mint NFT' : 'NFT(s) Minted!')
      setIsMintLoading(false)

      enqueueSnackbar(
        `${title}: Your NFT(s) have been successfully ${functionName === (CLAIM_RESERVED as IMintTypes) ? 'claimed' : 'minted'}.`,
        {
          variant: 'success',
        }
      )

      if (!!refetchMints) {
        refetchMints()
      }
    } catch (err) {
      if (err instanceof Error) {
        handleError(err)
      } else {
        handleError(new Error('Some unknown error occured'))
      }
    }
  }

  // const { prepareContractWriteArgs, prepareContractWriteEnabled } = useMemo(() => {
  //   switch (functionName) {
  //     case PUBLIC:
  //       return {
  //         prepareContractWriteArgs: [ethers.BigNumber.from(numberToMint), ECDSASignature],
  //         prepareContractWriteEnabled: enableMintConfig && ECDSASignature !== BASE_SIGNATURE && numberToMint > 0,
  //       }
  //     case PRIVATE:
  //       return {
  //         prepareContractWriteArgs: [ethers.BigNumber.from(numberToMint), maxMints, ECDSASignature],
  //         prepareContractWriteEnabled: enableMintConfig && ECDSASignature !== BASE_SIGNATURE && numberToMint > 0 && mintConfigCond,
  //       }
  //     case RESERVE:
  //       return {
  //         prepareContractWriteArgs: [ethers.BigNumber.from(numberToMint), ECDSASignature],
  //         prepareContractWriteEnabled: enableMintConfig && ECDSASignature !== BASE_SIGNATURE && numberToMint > 0,
  //       }
  //     case CLAIM_RESERVED:
  //       return {
  //         prepareContractWriteArgs: [],
  //         prepareContractWriteEnabled: enableMintConfig,
  //       }
  //     default:
  //       return {
  //         prepareContractWriteArgs: [],
  //         prepareContractWriteEnabled: false,
  //       }
  //   }
  // }, [functionName, numberToMint, ECDSASignature, enableMintConfig, mintConfigCond, maxMints])

  // const { config: mintWriteConfig } = usePrepareContractWrite({
  //   abi: CONTRACT_ABI as any,
  //   address: CONTRACT_ADDRESS,
  //   functionName,
  //   enabled: prepareContractWriteEnabled,
  //   cacheTime: 0,
  //   args: prepareContractWriteArgs,
  //   overrides: functionName !== CLAIM_RESERVED && {
  //     value: ethers.utils.parseEther(MINT_PRICE_ETH).mul(numberToMint),
  //   },
  //   onSuccess(data) {
  //     if (!!data) {
  //       console.log('Success!!')
  //       callMintOnContract?.()
  //       setMintStatusType(PENDING_CONTRACT_RESPONSE)
  //       setMintStatus(`Waiting for Smart Contract Response`)
  //     }
  //   },
  //   onError(error) {
  //     handleError(error)
  //   },
  //   onSettled() {
  //     setEnableMintConfig(false)
  //   },
  // })

  /* Initialize the actual publicMint write function & states. */
  // const {
  //   data: mintData,
  //   writeAsync: callMintOnContract,
  //   isLoading: isMintLoading,
  //   isSuccess: isMintStarted,
  // } = useContractWrite({
  //   ...(mintWriteConfig as UseContractWriteConfig),
  //   onError(error) {
  //     handleError(error)
  //   },
  // })

  /* Setup mint transaction response data */
  // useWaitForTransaction({
  //   hash: mintData?.hash,
  //   onSuccess(data) {
  //     if (!!data) {
  //       setMintStatusType(SUCCESS)
  //       setMintStatus(functionName === RESERVE ? 'Mint NFT' : 'NFT(s) Minted!')

  //       enqueueSnackbar(`${title}: Your NFT(s) have been successfully ${functionName === CLAIM_RESERVED ? 'claimed' : 'minted'}.`, {
  //         variant: 'success',
  //       })

  //       if (!!refetchMints) {
  //         refetchMints()
  //       }
  //     }
  //   },
  //   onError(txError) {
  //     handleError(txError)
  //   },
  //   onSettled() {
  //     if (ECDSASignature !== BASE_SIGNATURE) {
  //       setECDSASignature(BASE_SIGNATURE)
  //     }
  //   },
  // })

  const handleError = (error: Error) => {
    setIsMintLoading(false)
    if (error?.message) {
      const parsedError = parseError(error)

      setMintStatusType(ERROR)
      setMintStatus(parsedError)
      enqueueSnackbar(`${title} Error: ${parsedError}`, { variant: 'error' })
    }
  }

  return {
    numberToMint,
    setNumberToMint,
    mint,
    mintStatus,
    mintStatusType,
    isMintLoading,
  }
}

export default useMint
