import { IsJsonString } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

export const getECDSASignature = async (address: string | unknown, quantity: number, maxMints?: string) => {
  try {
    if (!address) {
      throw new Error("Address can't be empty")
    }

    if (!quantity || quantity <= 0) {
      throw new Error('You must select at least 1 or more for the quantity of NFTs to mint.')
    }

    let queryParams = `walletAddress=${address}&quantity=${quantity}`
    if (maxMints) {
      queryParams += `&maxMints=${maxMints}`
    }

    const response = await fetch(`${API_URL}/ecdsa?${queryParams}`, {
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error(response?.statusText ?? 'Some error occured while trying to get ECDSA')
    }

    const text = await response.text()

    if (!IsJsonString(text)) {
      throw new Error('invalid json')
    }

    const result = JSON.parse(text)

    if (result.stat === 'fail') {
      throw new Error(result.message)
    }

    return result.signature
  } catch (err: unknown) {
    throw err
  }
}
