import { MINT_STATUS, MINT_TYPES, PrivateMintProps } from '../../../types/mint'
import MintTemplate from './template'
import useMint from '../../../hooks/useMint'
import { useEffect, useState } from 'react'

const TITLE = 'Private Mint'
const { PRIVATE } = MINT_TYPES
const { SUCCESS } = MINT_STATUS

const PrivateMint = ({
  contract,
  totalMinted: privateTotalMinted,
  walletData,
  maxMints,
  refetchMints,
  totalMintsFromWalletStatus,
}: PrivateMintProps) => {
  const [maxAllowedMints, setMaxAllowedMints] = useState(0)
  const {
    numberToMint,
    setNumberToMint,
    mint: privateMint,
    mintStatus,
    mintStatusType,
    isMintLoading,
  } = useMint(TITLE, contract, walletData?.walletAddress ?? '', PRIVATE, refetchMints, maxMints)

  useEffect(() => {
    const allowedWhitelistMints = Number(maxMints)
    const whitelistMintsLeft = allowedWhitelistMints > privateTotalMinted ? allowedWhitelistMints - privateTotalMinted : 0

    setNumberToMint(0)
    setMaxAllowedMints(whitelistMintsLeft)
  }, [maxMints, privateTotalMinted, setNumberToMint])

  return (
    <MintTemplate
      title={TITLE}
      image="/6193a9d734b645095290f06a_2k_Sneak_Peek.png"
      type={PRIVATE}
      numberToMint={numberToMint}
      setNumberToMint={setNumberToMint}
      totalMinted={privateTotalMinted}
      mintStatus={mintStatus}
      mintStatusType={mintStatusType}
      isMintLoading={isMintLoading}
      mint={privateMint}
      maxMints={maxAllowedMints}
    />
  )
}

export default PrivateMint
