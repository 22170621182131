import { ethers } from 'ethers'
import { MINT_PRICE_ETH } from '../../../constants/mint'
import { MintTemplateProps, MINT_STATUS, MINT_TYPES } from '../../../types/mint'

const { ERROR, SUCCESS } = MINT_STATUS
const { RESERVE, CLAIM_RESERVED } = MINT_TYPES

/*
  TODO:
    1. What is 15/15?
*/

const MintTemplate = ({
  title,
  image,
  type,
  numberToMint,
  setNumberToMint,
  totalMinted,
  mintStatus,
  mintStatusType,
  isMintLoading,
  mint,
  maxMints,
}: MintTemplateProps) => (
  <div className="mt-8 md:mt-16 mb-8 md:mb-16 md:mx-16 border-[#369e68] border-solid border-2 bg-[#000b05]">
    <div className="container max-w-8xl p-5 md:px-9 mx-auto">
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 items-start">
        <div className="flex md:flex-none justify-center border-[#369e68] border-solid border-4 w-full">
          <img className="w-full" src={image} alt={title} />
        </div>
        <div className="md:flex-initial grid-cols-1 md:grid-cols-2 w-full">
          <div className="border-[#369e68] border-solid border-2 w-full md:w-1/2">
            <h2 className="border-solid border-b-2 border-[#369e68] text-2xl text-[#50f6a0] p-6">{title}</h2>
            <div className="text-[#50f6a0] p-6 text-2xl">
              <div>{MINT_PRICE_ETH} ETH</div>
              <div>15/15</div>
            </div>
          </div>
          <div className="main-header mx-6 my-6 text-4xl text-[#50f6a0]">
            The best of the bloodstalkers, modified by the finest artificers of the multiverse. Combined? Power unfathomable.
          </div>
        </div>

        <div className="md:flex-initial grid-cols-1 md:grid-cols-2 w-full">
          <div className="items-center justify-self-center flex-col">
            {type !== MINT_TYPES.CLAIM_RESERVED && (
              <div className="flex mb-4">
                <div className="custom-number-input h-10 w-32 mb-6 mt-6">
                  <label htmlFor="custom-input-number" className="w-full text-white font-semibold text-xl text-[#50f6a0]">
                    NFTs to Mint:
                  </label>
                  <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                    <button
                      data-action="decrement"
                      className=" bg-[#53ffa6] text-gray-600 hover:text-gray-700 hover:bg-[#44cb85] h-full w-20 rounded-l cursor-pointer outline-none"
                      onClick={() => {
                        setNumberToMint(numberToMint - 1)
                      }}
                    >
                      <span className="m-auto text-2xl font-thin">−</span>
                    </button>
                    <input
                      type="number"
                      value={numberToMint}
                      min="1"
                      max={maxMints ?? 10}
                      onChange={(event) => setNumberToMint(Number(event?.target.value))}
                      className="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                      name="custom-input-number"
                    />
                    <button
                      data-action="increment"
                      className="bg-[#53ffa6] text-gray-600 hover:text-gray-700 hover:bg-[#44cb85] h-full w-20 rounded-r cursor-pointer"
                      onClick={() => {
                        setNumberToMint(numberToMint + 1)
                      }}
                    >
                      <span className="m-auto text-2xl font-thin">+</span>
                    </button>
                  </div>
                </div>
                <div className="w-full h-10 align-middle pt-8 pl-6 text-md text-[#50f6a0]">
                  {numberToMint} x {MINT_PRICE_ETH}ETH ={' '}
                  <>{ethers.utils.formatEther(ethers.utils.parseEther(MINT_PRICE_ETH).mul(numberToMint))}ETH</>
                </div>
              </div>
            )}

            <div className="text-white">
              {type === MINT_TYPES.CLAIM_RESERVED && (
                <label className="w-full font-semibold text-xl text-[#50f6a0] mr-6">Claim Reserved Mint</label>
              )}
              {totalMinted > 0 && <p style={{ margin: '12px 0 5px', color: 'green' }}>You have minted {totalMinted} NFTs.</p>}

              {mintStatusType && mintStatus && (
                <p style={{ color: mintStatusType === ERROR ? 'red' : mintStatusType === SUCCESS ? 'green' : 'grey' }}>
                  {mintStatusType} {mintStatus}
                </p>
              )}

              <button
                style={{ marginTop: 5 }}
                className="hover:shadow-form hover:bg-[#44cb85] h-20 w-96 bg-[#53ffa6] text-[#000b05] text-2xl px-6 py-2 mt-4 md:mt-0 w-full-xs"
                data-mint-loading={isMintLoading}
                disabled={isMintLoading || mintStatusType?.includes('pending')}
                onClick={mint}
              >
                {isMintLoading || mintStatusType?.includes('pending') ? (
                  <div className="flex justify-center items-center">
                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>{' '}
                    {type === RESERVE || type === CLAIM_RESERVED
                      ? 'Waiting for approval'
                      : 'Minting ' + numberToMint + ' NFT' + (numberToMint > 1 ? 's' : '') + '!'}
                  </div>
                ) : type === CLAIM_RESERVED ? (
                  'Claim Reserved NFTs'
                ) : (
                  'Mint NFT' + (numberToMint > 1 ? 's' : '') + '!'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default MintTemplate
