import { MINT_TYPES, BaseMintProps } from '../../../types/mint'
import MintTemplate from './template'
import useMint from '../../../hooks/useMint'

const TITLE = 'Public Mint'
const { PUBLIC } = MINT_TYPES

const PublicMint = ({ contract, totalMinted: publicTotalMinted, walletData, refetchMints }: BaseMintProps) => {
  const {
    numberToMint,
    setNumberToMint,
    mint: publicMint,
    mintStatus,
    mintStatusType,
    isMintLoading,
  } = useMint(TITLE, contract, walletData?.walletAddress ?? '', PUBLIC, refetchMints)

  return (
    <MintTemplate
      title={TITLE}
      image="/Squiggleface.png"
      type={PUBLIC}
      numberToMint={numberToMint}
      setNumberToMint={setNumberToMint}
      totalMinted={publicTotalMinted}
      mintStatus={mintStatus}
      mintStatusType={mintStatusType}
      isMintLoading={isMintLoading}
      mint={publicMint}
    />
  )
}

export default PublicMint
