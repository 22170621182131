import { MINT_TYPES } from '../../../types/mint'
import useMint from '../../../hooks/useMint'
import MintTemplate from './template'
import Web3 from 'ethers'

const TITLE = 'Claim Reserved NFT(s)'
const { CLAIM_RESERVED } = MINT_TYPES

const ClaimReserveMint = ({ contract }: { contract: Web3.ethers.Contract | undefined }) => {
  const {
    setNumberToMint,
    mint: claimReservedMint,
    mintStatus,
    mintStatusType,
    isMintLoading,
  } = useMint(TITLE, contract, '', CLAIM_RESERVED)

  return (
    <MintTemplate
      title={TITLE}
      image="/6193a9d734b645095290f06a_2k_Sneak_Peek.png"
      type={CLAIM_RESERVED}
      numberToMint={0}
      setNumberToMint={setNumberToMint}
      totalMinted={0}
      mintStatus={mintStatus}
      mintStatusType={mintStatusType}
      isMintLoading={isMintLoading}
      mint={claimReservedMint}
    />
  )
}

export default ClaimReserveMint
