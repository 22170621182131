import { Dispatch } from 'react'
import Web3 from 'ethers'

export interface WhitelistData {
  walletAddress: string
  maxMints: string
}

export interface WalletData {
  connected: boolean
  walletAddress: string
  chainId: number
}

export interface BaseMintProps {
  contract: Web3.ethers.Contract | undefined
  totalMinted: number
  walletData: WalletData | undefined
  refetchMints: () => void
}

export type PrivateMintProps = BaseMintProps & {
  maxMints: string
  totalMintsFromWalletStatus: string
}

export enum MINT_TYPES {
  PUBLIC = 'mint',
  PRIVATE = 'privateMint',
  RESERVE = 'reserveMint',
  CLAIM_RESERVED = 'claimReservedMint',
}

export type IMintTypes = MINT_TYPES.PUBLIC | MINT_TYPES.PRIVATE | MINT_TYPES.RESERVE | MINT_TYPES.CLAIM_RESERVED

export enum MINT_STATUS {
  PENDING_ECDSA = 'pending_ecdsa',
  PENDING_CONTRACT_WRITE = 'pending_contractWrite',
  PENDING_CONTRACT_RESPONSE = 'pending_contractResponse',
  ERROR = 'error',
  SUCCESS = 'success',
}

export type MintStatusTypes =
  | MINT_STATUS.PENDING_ECDSA
  | MINT_STATUS.PENDING_CONTRACT_WRITE
  | MINT_STATUS.PENDING_CONTRACT_RESPONSE
  | MINT_STATUS.ERROR
  | MINT_STATUS.SUCCESS

export interface MintTemplateProps {
  title: string
  image: string
  type: IMintTypes
  numberToMint: number
  setNumberToMint: Dispatch<number>
  totalMinted: number
  mintStatus: string
  mintStatusType: MintStatusTypes | undefined
  isMintLoading: boolean
  mint: () => void
  maxMints?: number
}
