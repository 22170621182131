import { useState } from 'react'
import RainbowKitMint from './components/rainbowkit-mint/rainbowkit-mint.component'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.scss'
import 'animate.css'
import WalletButton from './components/wallet-button/wallet-button.component'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  )
}

const Home = () => {
  const [navbar, setNavbar] = useState(false)

  return (
    <>
      <nav id="header" className="h-16 md:h-24 sticky top-0 z-50 md:mt-12 md:mx-16 border-solid border-2 border-[#009548] bg-[#002210]">
        <div className="container max-w-8xl py-0 mx-auto">
          <div className="relative grid grid-cols-2 flex-row md:flex items-center md:justify-start	text-center justify-between text-[#009d4b] uppercase text-xl">
            <a
              className="order-first md:order-5 w-24 h-[3.9rem] md:w-1/2 flex justify-center border-solid border-r md:border-x border-[#009548] md:h-[5.9rem] md:px-6"
              href="/"
            >
              <img src="/mechachaotic-logo.png" className="h-12 md:h-20 pt-4" alt="MechaChaotic Logo" />
            </a>
            <a
              className={`order-1 w-full col-span-2 md:flex p-6 grow justify-center bg-[#002210] ${
                navbar ? 'animate__animated animate__slideInRight' : 'hidden'
              }`}
              href="https://www.mechachaotic.com/"
            >
              Main
            </a>
            <a
              className={`order-2 w-full col-span-2 md:flex p-6 grow  justify-center bg-[#002210] ${
                navbar ? 'animate__animated animate__slideInRight' : 'hidden'
              }`}
              href="https://www.mechachaotic.com/#FAQ"
            >
              FAQ
            </a>
            <a
              className={`order-7 w-full col-span-2 md:flex p-6 grow  justify-center bg-[#002210] ${
                navbar ? 'animate__animated animate__slideInRight' : 'hidden'
              }`}
              href="https://twitter.com/MechaChaoticNFT"
              rel="noreferrer"
              target="_blank"
            >
              Twitter
            </a>
            <a
              className={`order-8 w-full col-span-2 md:flex p-6 grow justify-center bg-[#002210] ${
                navbar ? 'animate__animated animate__slideInRight' : 'hidden'
              }`}
              href="https://discord.com/invite/mechachaotic"
              rel="noreferrer"
              target="_blank"
            >
              Discord
            </a>

            <div className="md:hidden flex order-first justify-end mr-4">
              <button className="outline-none menu-button" onClick={() => setNavbar(!navbar)}>
                <svg
                  className="w-6 h-6 text-gray-500"
                  x-show="! showMenu"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 00 24 24"
                  stroke="currentColor"
                >
                  <path d="m4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/*  First Hero / Body Content Section */}
      <div className="mt-16 md:mx-16 border-[#369e68] border-solid border-2 bg-[#000b05] text-[#4ded9a]">
        <div className="container max-w-8xl px-3 md:px-6 py-5 mx-auto">
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
              <div className="p-4 flex items-top justify-center">
                <div className="container mx-8">
                  <button className="h-20 bg-[#53ffa6] text-[#000b05] text-xl md:text-2xl px-6 md:px-12 py-2 w-full">
                    2nd Breach Collection <div className="uppercase text-xl">OpenSea</div>
                  </button>

                  <h1 className="main-header text-center font-semibold my-6 mb-2 md:mb-6">
                    The rift has opened and creatures of chaos are trying to spill into our world, looking for bonds to cement their
                    existence to this reality.
                  </h1>

                  <h1 className="main-header text-center font-semibold my-6 mb-2 md:mb-6">
                    Although they promise unimaginable power, you must be wary of these connections, as all of these creatures have motives
                    of their own, be it greed, gluttony, domination, or darker desires inscrutable to us mortals.
                  </h1>

                  <h1 className="main-header text-center font-semibold mt-6 mb-2 md:mb-6">Bond, Beware, Become.</h1>

                  <h1 className="main-header text-center font-semibold my-6 mb-2 md:mb-6">
                    To form a bond through the breach, connect your wallet and mint below…
                  </h1>
                  <br />
                  <h1 className="main-header text-center font-semibold my-6 mb-2 md:mb-6">if they will have you.</h1>
                </div>
              </div>
              <div className="rounded-md flex items-center justify-center flex-col">
                <div
                  className="w-full border-[#369e68] border-solid border-2 bg-[#000b05]"
                  style={{
                    backgroundImage: 'url(/hero-bg.png)',
                    width: '100%',
                    height: '100%',
                    display: 'inherit',
                  }}
                ></div>
                <WalletButton />
              </div>
            </div>
          </div>
          {/* <WalletButton /> */}
        </div>
      </div>

      <RainbowKitMint />
    </>
  )
}

const NotFound = () => {
  return (
    <>
      <div>404 page not found.</div>
    </>
  )
}

export default App
